.home{
    text-align: center;
}
.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
    gap: 10px;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transform: scale(.0);
    transition: 0.5s ease-in-out;
}

.main-active{
    opacity: 1;
    transform: scale(1);
    pointer-events: visible;
}
.main .name{
    font-size: 80px;
    font-weight: bold;
    color: rgb(255, 94, 0);
    font-family: monospace;
}

.main h4{
    font-size: 30px;
}

.main h3{
    font-size: 40px;
}

.main button{
    background-color: rgb(255, 94, 0);
    outline: none;
    border: none;
    color: white;
    text-transform: capitalize;
    padding: 20px 50px;
    font-size: 20px;
    cursor: pointer;
    margin-top: 30px;
    font-weight: 600;
}

.main button:hover{
    color: rgb(255, 94, 0);
    background-color: #fff;
}

@media (max-width:960px){
    .main{
        min-height: 60vh;
    }

    .main .name{
        font-size: calc(1.375rem + 1.5vw);
    }

    .main h3{
        font-size: 30px;
    }

    .main button{
        padding: 10px 20px;
        font-size: 1rem;
    }
}