.contact{
    padding: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    gap: 2rem;
}

.ready{
    color: #fff;
    background-color: rgb(255, 94, 0);
    padding: 42px;
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    border-radius: 25px;
}

.ready a{
    text-decoration: none;
}

.address{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.number{
    display: flex;
    align-items: center;
    gap: 5px;
}


.contactUS{
    padding: 20px;
    border: 1px solid rgb(255, 94, 0);
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
}
@media (max-width:960px) {
    .contact{
        padding: 3rem 30px;
    }
    .ready{
        font-size: 1rem;
    }
}