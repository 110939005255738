.testy{
    padding: 100px;
    text-align: center;
    overflow: hidden;
}

.client{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    /* display: flex;
    gap: 50px;
    overflow-x: scroll;
    width: 100%; */
}

.testi{
    background: #fff;
    padding: 1.5rem;
    margin-top: 100px;
    transition: .5s ease-in-out;
}

.clientImg{
    width: 150px;
    height: 150px;
    margin-bottom: -50px;
    border-radius: 50%;
    transform: translateY(-50%);
    border: 3px solid rgb(255, 94, 0);
}

.testi p{
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 30px;
}

.testi h4{
    font-size: 25px;
    font-weight: 500;
    margin-top: 10px;
}

.testi h5{
    font-size: 20px;
    font-weight: 500;
}

.client span{
    color : 'rgb(255, 94, 0)';
    font-size: '2rem'; cursor: 'pointer';
    cursor: 'pointer'
}

@media (max-width:960px){
    .testy{
        padding: 3rem 30px;
    }

    .testi{
        padding: 1rem;
    }

    .testi h4{
        font-size: 1rem;
    }

    .testi h5{
        font-size: 0.8rem;
    }
    .testi p{
        font-size: 1rem;
    }

    .clientImg{
        width: 100px;
        height: 100px;
    }

    .quote{
        font-size: 1rem;
    }
    .client{
        gap: 1.5rem;
    }
}