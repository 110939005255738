.carusole_wrapper{
    width: 100%;
    min-height: 80vh;
    position: relative;
}

.controlLeft{
    position: absolute;
    top: 50%;
    left: 15px;
    color: rgb(255, 94, 0);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
}

.controlRight{
    position: absolute;
    top: 50%;
    right: 15px;
    color: rgb(255, 94, 0);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
}

.carusole_pagination{
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
}

.pagination{
    height: 10px;
    width: 10px;
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}

.pagination:hover{
    transform: scale(1.2);
}

.pagination-active{
    background-color: rgb(255, 94, 0);
}

@media (max-width:960px){
    .controlLeft, .controlRight{
        font-size: 1rem;
    }
    .carusole_wrapper{
        min-height: 60vh;
    }
    .controlRight{
        right: 5px;
    }
    .controlLeft{
        left: 5px;
    }
}