.footer{
    background-color: #212121;
    text-align: center;
}

.top{
    display: flex;
    justify-content: center;
    gap: 1.3rem;
    padding: 100px 100px;
}

.footerLogo{
    width: 250px;
    height: 100px;
}

.clicklable{
    color: rgb(255, 94, 0);
    font-size: 1rem;
    display: flex;
    margin-top: 10px;
    gap: 10px;
    justify-content: center;
}

.footerIcon{
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(255, 94, 0);
    cursor: pointer;
}

.footerIcon a{
    color: rgb(255, 94, 0);
}

.follow{
    color: #fff;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 500;
}

.logoSec p {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 500;
}

.logoSec{
    text-align: center;
}

.bottom{
    color: rgba(255, 255, 255, 0.5) !important;
    font-size: 0.7rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    padding: 20px 100px;
}

hr{
    color: rgba(255, 255, 255, 0.5);
    size: 1px;
}

.bottom h2{
    font-weight: 400;
}

.bottom h2 a{
    text-decoration: none;
    color: inherit;
}

.caru{
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

@media (max-width:960px){
    .top{
        padding: 3rem 30px;
    }

    .footerLogo{
        width: 200px;
        height: 50px;
    }

    .bottom{
        padding: 10px 30px;
        flex-direction: column;
        justify-content: center;
    }
}