.service{
    padding: 100px;
    text-align: center;
}

.title{
    color: rgb(255, 94, 0);
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 5px;
}

.subTitle{
    font-size: 2.5rem;
    font-weight: 700;
}

.boxes{
    display: flex;
    margin: 3rem 0;
    align-items: center;
    gap: 3rem; 
}

.eachBox{
    padding: 3rem 1.5rem;
    transition: .5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    gap: 0.7rem;
    flex: 1;
    /* flex-grow: 1; */
    /* flex-basis: 0; */
}

.eachBox:hover{
    -webkit-box-shadow: 0px 0px 70px -30px rgba(101,101,101,1);
    -moz-box-shadow: 0px 0px 70px -30px rgba(101,101,101,1);
    box-shadow: 0px 0px 70px -30px rgba(101,101,101,1);
}

.iconImg{
    font-size: 2em;
    color: rgb(255, 94, 0);
    background-color: #fff;
    padding: 20px;
    display: flex;
    transition: .5s;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(255, 94, 0);
}

.plans{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
    margin-top: 2rem;
}

.plan{
    background-color: #fff;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
    border: 2px solid rgb(255, 94, 0);
    justify-content: space-between;
    height: 100%;
}

.features{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    width: 80%;
}

.features p{
    text-align: left;
    position: relative;
}

.features p::after{
    position: absolute;
    content: "";
    top: 7px;
    left: -15px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: rgb(255, 94, 0);
}

.bookBtn{
    padding: 10px 20px;
    outline: none;
    border: none;
    margin-top: 20px;
    background: rgb(255, 94, 0);
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    transition: .5s;
    border: 1px solid rgb(255, 94, 0);
}

.bookBtn:hover{
    color: rgb(255, 94, 0);
    background: #fff;
}

.packTitle{
    font-size: 1.25rem;
    font-weight: 500;
}

.eachBox h3{
    font-size: 1.25rem;
    font-weight: 500;
}

.eachBox p{
    color: #656565;
    font-weight: 500;
}

.eachBox:hover > .iconImg{
    color: #fff;
    background-color: rgb(255, 94, 0);
}

.umrah{
    display: flex;
    gap: 10px;
    background-color: rgb(255, 94, 0);
    /* text-align: center; */
    margin-top: 1.5rem;
}

.umrah img{
    max-width: 50%;
}

.umrah .umrahDetails{
    color: #fff;
    text-align: center;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media (max-width:960px) {
    .service{
        padding: 3rem 30px;
    }
    .boxes{
        flex-direction: column;
    }
    .subTitle{
        font-size: calc(1.375rem + 1.5vw);
    }

    .plans{
        flex-direction: column;
        }
    .umrah{
        flex-direction: column;
    }
    .umrah img{
        max-width: 100%;
    }
    .umrahDetails{
        min-height: 200px;
    }
}