.header-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}
.header{
    display: flex;
    justify-content: space-between;
    padding: 10px 100px;
    align-items: center;
    top: 0;
    background-color: #fff;
}


.header .logo{
    width: 200px;
    height: 70px;
}
.navList{
    display: flex;
    list-style-type: none;
    gap: 20px;
    font-size: 18px;
}

.navList li{
    font-weight: 500;
    cursor: pointer;
}

.navList li a{
    text-decoration: none;
    color: rgb(255, 94, 0);
}

.navToggler{
    padding: 0.7rem 0.7rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: rgb(255, 94, 0);
    border: 1px solid;
    justify-self: flex-end;
    color: white;
    position: absolute;
    right: 30px;
    border-radius: 50%;
    display: none;
}

@media (max-width:960px){
    .header{
        padding: 10px 30px;
        position: relative;
    }

    .header .logo{
        width: 120px;
    }

    .navToggler{
        display: block;
    }

  

    .navBar{
        position: absolute;
        top: 90px;
        left: 0;
        right: 0;
        z-index: 999;
        height: 0px;
        transition: height 0.5 ease-in-out;
        overflow: hidden;
        transition: all 0.5s ease-in;
        visibility: hidden;
      }
      
      .navBar.expanded{
        height: 245px;
        visibility: visible;
        transition: all 0.5s ease-in-out;
      }
      
      
      
      .navList{
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        padding-bottom: 40px;
      }
}