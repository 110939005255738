.App{
    background-color: #f3f3f3;
    scroll-behavior: smooth;
}

button a{
    text-decoration: none;
    color: inherit;
}

@media (max-width:960px) {
    section{
        padding: 15px;
    }
}