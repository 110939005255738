.about{
    padding: 100px;
    min-height: 80vh;
    overflow: hidden;
}

.aboutTop{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: center;
}

.aboutImg{
    background-image: url('../assets/arabia.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 90vh;
}
.aboutText{
    padding: 50px;
    background-color: #fff;
    /* min-height: 62vh; */
    transform: translateX(-10%) scaleY(0.95);
    width: 100%;
}


.aboutText h2{
    font-size: 2.5rem;
    font-weight: 700;
}

.aboutText p{
    color: #656565;
    font-size: 1rem;
    margin-top: 10px;
    /* text-transform: capitalize; */
}

.image{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
}

.image img{
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.aboutBtn{
    padding: 10px 20px;
    outline: none;
    border: none;
    margin-top: 20px;
    background: rgb(255, 94, 0);
    color: #fff;
    font-size: 15px;
    cursor: pointer;
    transition: .5s;
    border: 1px solid rgb(255, 94, 0);
}

.aboutBtn:hover{
    color: rgb(255, 94, 0);
    background: #fff;
}

.aboutBottom{
    display: flex;
    margin-top: 50px;
    gap: 2rem;
}

.aboutBottom .box{
    display: flex;
}

.icon{
    color: #fff;
    background-color: rgb(255, 94, 0);
    font-size: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 40px;
    margin-right: 16px;
}

.detials{
    display: flex;
    flex-direction: column;
}

.certificate{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
}

.certificate img{
    height: 200px;
    width: 200px;
    object-fit: cover;
    border-radius: 50%;
}

.certificate img:nth-child(4){
    height: 250px;
    width: 250px;

}



.gallery{
    margin-top: 3rem;
}

.gallery h1{
    text-align: center;
    margin-bottom: 2rem;
}

.videogrid{
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr; 
    grid-template-rows: auto 1fr auto; 
    gap: 1rem;
    justify-items: center;
}

.videogrid:nth-child(1){
    grid-area: first1;
}

.videogrid:nth-child(2){
    grid-area: first2;
}

.videogrid:nth-child(3){
    grid-area: first3;
}

.videogrid:nth-child(4){
    grid-area: second;
}

.videogrid:nth-child(5){
    grid-area: second2;
}



.videoContainer{
    height: 500px;
    width: 100%;
}

.videoContainer img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.videoContainer video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.detials h3{
    font-size: 1.25rem;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

.detials h5{
    font-size: 1rem;
    font-weight: 400;
    color: #656565;
}

@media (max-width:960px){
    .about{
        padding: 3rem 30px;
    }
    .aboutTop{
        grid-template-columns: 1fr;
        gap: 3rem;
    }
    .aboutText{
        transform: translateX(0%);
        padding: 1.5rem;
        width: inherit;
    }
    .aboutText h2{
        font-size: calc(1.375rem + 1.5vw);
    }

    .aboutImg{
        height: 80vh;
    }

    .aboutBottom{
        flex-direction: column;
    }

    .detials h3{
        font-size: 1.1rem;
    }

    .videogrid{
        grid-template-columns: 1fr;
        gap: 2rem;
        grid-template-areas: 
    "first"
    "second"
    ;
    }
    .videoContainer{
        height: 350px;
    }

    .certificate{
        /* flex-direction: column; */
        justify-content: space-evenly;
        /* gap: 0.5rem; */
    }

    .certificate img{
        height: 50px;
        width: 50px;
    }

    .certificate img:nth-child(4){
        height: 70px;
        width: 70px;
    }
}